import { ReactNode } from "react";
import { Link, useColorMode, useColorModeValue } from "@chakra-ui/react";

interface IProps {
  label: string;
  url: string;
}

export const NavLink = ({ link }: { link: IProps }) => (
  <Link
    px="2"
    py="1"
    rounded="md"
    style={{ color: "white" }}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("green.200", "green.700"),
    }}
    href={link.url}
  >
    {link.label}
  </Link>
);
