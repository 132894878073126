import { useState, useEffect } from "react";
import { getBlogs } from "./api/db.service";

export const useBlogData = () => {
  const [value, setValue] = useState<any[]>([]);

  useEffect(() => {
    console.log("fetch");
    const fetchData = async () => {
      try {
        const blogs: any[] = await getBlogs();
        setValue(blogs);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  return {
    value
  }
};
