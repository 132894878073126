import { Routes, Route, Navigate } from "react-router-dom";
import { Home } from "./pages/home/Home";
import { SingleBlog } from "./pages/view-blog/SingleBlog";
import { AddBlog } from "./pages/add-blog/AddBlog";

const PublicRoutes = () => (
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/add-blog" element={<AddBlog/>} />
        <Route path="/view-blog" element={<SingleBlog/>} />
        <Route path="*" element={<Navigate to="/" replace={true}/>}/>
    </Routes>
);

export default PublicRoutes;