import { Navbar } from './components/Navigation/NavigationBar';
import PublicRoutes from './routes';
import './App.css';
import { Footer } from './components/Footer/Footer';


function App() {
  return (
    <div style={{position: 'relative',height: '100vh'}}>
      <Navbar.Desktop/>
      <PublicRoutes />
    </div>
  );
}

export default App;
