import axios from "axios";

interface IBlog {
    title: string;
    author: string;
    note: string;
}

// const baseUrl = 'http://localhost:3005/blogs';
const baseUrl = 'https://txt-core-prod-014e61ff9ca1.herokuapp.com/blogs'

export const getBlogs = async(): Promise<any> =>{
    const response = await axios.get(`${baseUrl}/all-blogs`);
    return response.data.payload;
};  

export const addBlog = async(blog: IBlog): Promise<any> => {
    await axios.post(`${baseUrl}/create-blog`, blog);
};