import { Card, CardBody, Stack, Heading, Text, Flex } from "@chakra-ui/react";

interface IProp {
  title: string;
  author: string;
  note: string;
}

export const BaseCard = ({ blog }: { blog: IProp }) => {
  const { title, author, note } = blog;
  return (
    <Card maxW={"sm"} color='white' bg='black' mt='15px'>
      <CardBody p="1rem">
        <Stack mt="6" spacing="3">
          <Heading size="md">{title}</Heading>
          <Heading size="sm">{author}</Heading>
          <Text>{note}</Text>
        </Stack>
      </CardBody>
    </Card>
  );
};
