import {
  Box,
  HStack,
  Heading,
  Button,
} from "@chakra-ui/react";
import { NavLink } from "../Links/NavLink";
import { AddIcon } from "@chakra-ui/icons";
import {useNavigate} from 'react-router-dom';

const NavbarDesktop = () => {
    const navigate = useNavigate();
  return (
    <Box
      bgColor="black"
      p="25px"
      display="flex"
      flexDir="row"
      justifyContent="space-around"
    >
      <Heading color={"white"}>Blog Application</Heading>
      <HStack gap="24px">
        <NavLink link={{ label: "View Blogs", url: "/" }} />
        <Button
          variant="solid"
          colorScheme={"green"}
          size="sm"
          mr="4"
          leftIcon={<AddIcon />}
          onClick={() => {navigate('/add-blog')}}
        >
          Add Blog
        </Button>
      </HStack>
    </Box>
  );
};

export const Navbar = {
  Desktop: NavbarDesktop,
};
