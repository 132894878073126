import {
  Flex,
  Box,
  VStack,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { BsPerson } from "react-icons/bs";
import { MdOutlineEditNote } from "react-icons/md";
import { useAddBlogForm } from "../../hooks/useAddBlogForm";

interface IProps {}

export const Form = () => {
  const form = useAddBlogForm();
  return (
    <Box bgColor="black" w="500px" p="30px" borderRadius={"lg"}>
      <Box color="white">
        <form >
          <VStack spacing={5}>
            <FormControl id={form.title} isRequired>
              <FormLabel>Blog Title</FormLabel>
              <InputGroup borderColor="#E0E1E7">
                <InputLeftElement
                  pointerEvents="none"
                  children={<MdOutlineEditNote color="gray.800" />}
                />
                <Input value={form.title} onChange={form.onTitleChange} type="text" size="md" />
              </InputGroup>
            </FormControl>
            <FormControl id={form.author} isRequired={true}>
              <FormLabel>Author Name</FormLabel>
              <InputGroup borderColor="#E0E1E7">
                <InputLeftElement
                  pointerEvents="none"
                  children={<BsPerson color="gray.800" />}
                />
                <Input value={form.author} onChange={form.onAuthorChange} type="text" size="md" />
              </InputGroup>
            </FormControl>
            <FormControl id={form.note} isRequired={true}>
              <FormLabel>Blog Content</FormLabel>
              <Textarea
                value={form.note}
                onChange={form.onNoteChange}
                borderColor="#E0E1E7"
                _hover={{
                  borderRadius: "gray.300",
                }}
              />
            </FormControl>
            <FormControl id="name">
              <Button
                type="button"
                variant="solid"
                bg="green.400"
                color="white"
                w="100%"
                onClick={form.handleSubmit}
              >
                Post Your Blog
              </Button>
            </FormControl>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};
