import { BaseCard } from "../../components/Card/BaseCard";
import { Box, Flex, Grid } from "@chakra-ui/react";
import { useBlogData } from "../../hooks/data";

export const Home = () => {
  const blogs = useBlogData();

  return (
    <Flex justifyContent="center">
      <Grid templateColumns="repeat(3, 1fr)" gap="2">
        {blogs.value.map((blog: any) => (
          <BaseCard key={blog.id} blog={blog} />
        ))}
      </Grid>
    </Flex>
  );
};
