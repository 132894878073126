import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addBlog } from '../hooks/api/db.service';

interface IBlogs {
    title: string;
    author: string;
    note: string;
}

export const useAddBlogForm = () => {
    const [title, setTitle] = useState<string>('');
    const [author, setAuthor] = useState<string>('');
    const [note, setNote] = useState<string>('');
    const navigate = useNavigate();

    const handleSubmit = async () => {
       try{
        const obj: IBlogs = {
            title: title,
            author: author,
            note: note
        }

        // Wait for the post to create
        await addBlog(obj);
        // Clear data
        setTitle('');
        setAuthor('');
        setNote('');
        // Move to the main
        navigate('/', {replace: true});

        console.log("nav")
       }catch(e){
        console.log(e)
       }
    };

    const onTitleChange = (e: any) => {
        setTitle(e.target.value);
    };

    const onAuthorChange = (e: any) => {
        setAuthor(e.target.value);
    };

    const onNoteChange = (e: any) => {
        setNote(e.target.value);
    };

    return {
        title,
        author,
        note,
        handleSubmit,
        onTitleChange,
        onAuthorChange,
        onNoteChange
    }
};