// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  components: {
    Link: {
      variants: {
        // you can name it whatever you want
        primary: ({ colorScheme = "blue" }) => ({
          color: `${colorScheme}.500`,
          _hover: {
            color: `${colorScheme}.400`,
          },
        }),
      },
      defaultProps: {
        // you can name it whatever you want
        variant: "primary",
      },
    },
  },
});
